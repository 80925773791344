@import '../../../../core_template/static/core_template/scss-src/default.scss';
@import './lib/variables';
@import './lib/mixins';
@import './lib/typography';


body.htc-investors{
  background-color: #f8f9fa !important;
  color: $col-text;
  &.body-fixed{
    overflow: hidden;
  }
  .rich-text{
    img{
      max-width: 100%;
      height: auto;
    }
    a{
      color: $col-htc-green;
      text-decoration: underline;
    }
  }
  b{
    font-weight: 500;
  }
  a{
    color: $col-text;
    text-decoration: none;
    &:hover{
      color: $col-link-hover;
    }
    &.btn{
      color:#fff;
    }
  }

  iframe[src*="eurolandir"]{
    width:100%;
  }

  .text-htc{
    color: $col-htc-green !important;
  }
  .btn-htc {
    background-color: $col-htc-green !important;
    border-color: $col-htc-green !important;
    &:hover{
      background-color: $col-htc-green-hover !important;
      border-color: $col-htc-green-hover !important;
    }
  }
  table.dataTable{
    tbody{
      tr{
        td{
          .file-link, .direct-link{
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
  .width-overflow-auto{
    overflow: auto;
  }

  &>header{
    padding-bottom: 54px;
    @media (max-width: ($tablet-max-width)){
      padding-bottom: 48px;
    }
  }
  .section-header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 11;

    border-bottom: 1px solid #efefef;
    @media (max-width: ($tablet-max-width)){
      height: 48px;
    }
    .navbar{
      height: 100%;
      padding: 0;
      .container{
        height: 100%;
        .navbar-collapse{
          padding-left: 0;
          padding-right: 0;
        }
      }

      .navbar-nav {
        a{
          color: $col-text;
        }
        &>li.nav-item{
          &>a.nav-link{
            font-size: 14px;
            @media (max-width: ($tablet-max-width)){
              width: calc(100% - 100px);

            }
          }
          @media (max-width: ($tablet-max-width)){
            &>i{
              height: 49px;
            }
          }
          a.dropdown-item{
            @media (min-width: ($laptop-min-width)){
              font-size: 12px;
            }
            font-size: 14px;
          }
          @media (min-width: ($laptop-min-width)){
            &>a{
              color: $col-text !important;
              &:hover{
                color: $col-text !important;
              }
              position: relative;
              &:after{
                content:"";
                width: 100%;
                height: 3px;
                background-color: $col-htc-green;
                position: absolute;
                bottom: -1px;
                left: 0;
                display: none;
                border: none !important;
                margin: 0;
              }

            }
            &:hover{
              &>a:after{
                display: block;
              }
            }
          }
          @media (max-width: ($tablet-max-width)){
            border-bottom: 1px solid #efefef;
          }
        }
        li{
          a{
            display:flex;
            align-items: center;
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            margin: 0 .75rem;
            height: 53px;
            @media (max-width: ($tablet-max-width)){
              height: 48px;
            }
          }
          .dropdown-menu{
            background-color: #f8f9fa;
            &:after{
              display: none;
            }
            @media (min-width: ($laptop-min-width)){
              padding: 10px 0;
              display: none !important;;
              flex-wrap: nowrap;
              z-index: 1;
              background-color: transparent;
              &:after{
                content:"";
                display: block;
                position: fixed;
                top: 53px;
                left: 0;
                width: 100%;
                background-color: hsla(0,0%,100%,.9);
                height: 48px;
                z-index: 1;

              }
              a{
                position: relative;
                z-index: 2;
                padding-left: 20px;
                padding-right: 10px;
                color: $col-link;
                &:hover{
                  background-color: transparent;
                  color: $col-text;
                }
              }
              &.web-show{
                display: flex !important;
              }
            }
            @media (max-width: ($tablet-max-width)){
              border: none;
              padding-left: 20px;
              padding-right: 20px;
              padding-top: 0;
              padding-bottom: 20px;
            }
            a{
              justify-content: flex-start;
              margin: 0;
              height: auto;
              @media (max-width: ($tablet-max-width)){
                margin-top: 5px;
                margin-bottom: 5px;
                color: $col-link;
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .nav-change-language{
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      a{
        width: auto !important;
        margin: 0 12px !important;
        &:first-child{
          margin-left: 20px !important;
        }
      }
      &:hover{
        a:after{
          display: none !important;
        }
      }
    }
  }
  .section-side-menu{
    p{
      @include font-style-6();
      margin-bottom: 1.25rem;
    }
    a{
      // color: $col-htc-green;
      @include font-style-6();
    }
  }
  .section-footer{
    .section-footer-bottom{
      .footer-link-group{
        a{
          display: inline-block;
          color: $col-link;
          &:hover{
            color: $col-text;
          }
        }
        .group-item{
          margin-bottom: 1rem;
          .item-list{
            margin-bottom: 1rem;
            .item-single{
              margin: .5rem 0;
            }
          }
        }

      }
      .copyright-block{
        h5, .copyright-wording{
          @include font-style-6;
          width: 100%;
        }
      }
    }
  }
  .section-dropdown-select{
    .dropdown{
      width: 240px;
      .dropdown-toggle{
        outline: none;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,.15);
        width: 100%;
        position: relative;
        text-align: left;
        padding: 7px 12px;
        padding-right: 30px;
        @include font-style-4();
        &:after{
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .dropdown-menu{
        width: 100%;
        background-color: #f8f9fa;
        .dropdown-item:hover{
          background-color: #f2f2f2;
        }
        a{
          @include font-style-6();
        }
      }
    }
  }
  .section-table{
    @media screen and (max-width: ($mobile-max-width__bootstrap)) {
      overflow-x: auto;
    }
    i.fa{
      color: $col-htc-green;
      &:hover{
        color: $col-htc-green-hover;
      }
    }
    td{
      img{
        @media (max-width: ($tablet-max-width)){
          max-width: 30vw;
        }
      }
    }
  }
  .section-card-list-columns{
    .card-text{
      .card-text-list{
        margin-bottom: 0.5rem;
        a,p{
          line-height: 1.4;
          display: inline-block;
        }
      }
    }
  }
  .section-full-wide-carousel{
    .carousel-item:after{
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.2);
    }
  }
  .section-image-and-desc-two-columns,
  .section-image-and-desc-two-columns-carousel{
    .text-block{
      @media (max-width: ($tablet-max-width)){
        margin-top: 25px;
      }
      h3{
        font-weight: 500;
      }
      h4{
        color: $col-link-hover;
      }
    }
  }
  .section-my-stock-info{
    .my-stock-info-currency{
      font-size: 36px;
      font-weight: 200;
      margin-right: 7px;
    }
    .my-stock-info-price{
      font-size: 100px;
      font-weight: 100;
      line-height: 1;
    }
  }
}
