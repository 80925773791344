@import './lib/variables';
@import './lib/mixins';
@import './lib/typography';


section{
  width: 100%;
  .carousel,
  .owl-carousel{
    .carousel-control-prev-icon.text-dark {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
    }
    .carousel-control-next-icon.text-dark {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
    }
  }
}

.section-full-wide-carousel{
  .carousel-inner{
    z-index: 1;
  }
  .carousel-control-prev,
  .carousel-control-next{
    z-index: 2;
    width: 10%;
    @media screen and (max-width: ($laptop-max-width)) {
      width: 35px;
    }
    @media screen and (max-width: ($tablet-max-width)) {

    }
    @media screen and (max-width: ($m-max-width__bootstrap)) {

    }
    .carousel-control-next-icon,
    .carousel-control-prev-icon{
      @media screen and (max-width: ($m-max-width__bootstrap)) {
        width: 1.6rem;
        height: 1.6rem;
      }

    }
  }
  .carousel{
    position: relative;
    picture{
      img{
        height: 600px;
        object-fit: cover;
        object-position: center;
        @media screen and (max-width: ($laptop-max-width)) {
          height: 600px;
        }
        @media screen and (max-width: ($tablet-max-width)) {
          height: 600px;
        }
        @media screen and (max-width: ($mobile-max-width__bootstrap)) {
          height: 500px;
        }
      }
    }
    .container{
      position: absolute;
      z-index: 2;
      top: 50%;
      height: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media screen and (max-width: ($m-max-width__bootstrap)) {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}
.section-image-tile{
  .image-block {
    border: 3px solid white ;
    background-color: black;
    padding: 0px;
    margin: 0px;
    height:200px;
    text-align: center;
    vertical-align: bottom;
    &:hover{
      >p{
        background-color: rgba(3,3,3,0.5);
        color: white;
      }
    }
    >p{
      width: 100%;
      height: 100%;
      padding-top: 150px;
      background-color: rgba(3,3,3,0.0);
    }
  }
}
.section-modal-video{
  .modal-dialog {
    max-width: 800px;
    margin: 30px auto;
  }
  .modal-body {
    position:relative;
    padding:0px;
  }
  .close {
    position:absolute;
    right:-30px;
    top:0;
    z-index:999;
    font-size:2rem;
    font-weight: normal;
    color:#fff;
    opacity:1;
  }
}
.section-image-and-desc-two-columns-carousel{

  @import 'lib/owl-carousel/core';
  @import 'lib/owl-carousel/theme.default';
  .owl-carousel{
    position: relative;
    .owl-stage{
      display: flex;
      align-items: center;
      .owl-item{
        .container{
          padding-left: 65px;
          padding-right: 65px;
        }
      }
    }
    .owl-nav{
      margin: 0;
      position: absolute;
      width: 100%;
      height: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%);

      .owl-prev,
      .owl-next{
        opacity: .5;
        background: transparent;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
      .owl-prev:hover,
      .owl-next:hover{
        opacity: 1;
        background: transparent;
      }
      .owl-prev{
        left: 0px;
      }
      .owl-next{
        right: 0px;
      }
    }
  }
}
.section-card-list-columns{
  .card-img-top{
    width: 100%;
    overflow: hidden;
    img{
      width: 100%;
      transition: transform .25s;
      transform: scale(1);
      &:hover{
        transform: scale(1.1);
      }
    }
  }
}
.section-page-title{
  background-color: #ccc;
  height: 150px;
  background-position: center;
  background-repeat: none;
  background-size: cover;
  position: relative;
  &:after{
    content:"";
    background-color: rgba(0,0,0,.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media (max-width: ($tablet-max-width)){
    height: 105px;
  }
  .container{
    height: 100%;
    position: relative;
    z-index: 1;
    .content-area{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h3,h2,h1,p{
    margin: 0;
  }
}

.section-header{
  nav{
    z-index: 10;
    position: relative;
  }
  .navbar-nav__language{
    white-space: nowrap;
  }
  @media (min-width: ($laptop-min-width)){
    .navbar-expand-custom,
    .navbar-expand-custom .container {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
        align-items: center;
    }
    .navbar-expand-custom .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-custom .navbar-collapse {
        display: flex!important;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
  }
  @media (max-width: ($tablet-max-width)){
    .navbar-collapse{
      padding: 1rem 1.5rem 1rem 1.5rem;
      background-color: #f8f9fa;
      position: absolute;
      width: 100%;
      left: 0;
      top: 100%;
      overflow: auto;
      max-height: calc( 100vh - 70px);

      .nav-link{
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        height: 1.5em;
      }
    }
  }
  .nav-hover-menu{
    .dropdown-toggle:after{
      display: none;
    }
    .dropdown{
      .dropdown-toggle{
        display: flex;
        justify-content: space-between;
        align-items: center;

      }
      i{
        width: 55px;
        position: absolute;
        right: 0;
        top: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media screen and (min-width: ($laptop-min-width)) {
          display: none;
        }
      }
      .dropdown-menu{
        @media screen and (min-width: ($laptop-min-width)) {
          margin-top: 0;
          position: absolute;
          display: none;
          &.web-show{
            display: block;
          }
        }
      }
    }
  }
}

.section-table{
  caption{
    caption-side: top;
  }
}

